import {ID, Response} from '../../../../../_metronic/helpers'

export interface ClientModel {
    id: ID
    name: string
    code?: string
    address?: string
    city?: string
    pincode?: string
    mobileNumber?: string
    whatsappNumber?: string
    email?: string
    tag?: string
    remarks?: string
    crBy?: string
    crDt?: Date
    upBy?: string
    upDt?: Date
  }

  export type ClientsQueryResponse = Response<Array<ClientModel>>

  export const initialClient: ClientModel = {
    id: 0,
    name: 'Moiz Travadi',
    city: 'Rajkot',
    mobileNumber: '9428349352',
  }