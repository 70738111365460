import {useListView} from '../../core/ListViewProvider'
import {ClientsListToolbar} from './ClientsListToolbar'
import {ClientsListGrouping} from './ClientsListGrouping'
import {ClientsListSearchComponent} from './ClientsListSearchComponent'

const ClientsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ClientsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ClientsListGrouping /> : <ClientsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ClientsListHeader}
