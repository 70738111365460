import axios, { AxiosResponse } from "axios";
import qs from "qs";
import {ID, Response} from '../../../../../_metronic/helpers'
import { ClientModel, ClientsQueryResponse } from "./_models";

const API_URL = process.env.REACT_APP_API_URL
export const CLIENT_URL = `${API_URL}/client`
export const GET_CLIENTS = `${CLIENT_URL}/read.php`

const getClients = (query: string): Promise<ClientsQueryResponse> => {
  console.log(query)
  return axios
    .get(`${GET_CLIENTS}?${query}`)
    .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
}

// export function f_get_clients() {
  
//     var data = {
//     }
  
//     let response = axios.get<ClientsQueryResponse>(GET_CLIENTS, 
//       {
//         params: qs.stringify(data),
//         // headers: {
//         //   'Content-Type': 'application/x-www-form-urlencoded',
//         // }
//       }
//     )
//     .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
//     .catch(function(error){
//       if(error.response){
//         console.log(error.response.data.error[0])
//       }
//       else{
//         console.log(error)
//       }
//       return Promise.reject(error)
//     });
  
//     return response
  
//   }

const getClientById = (id: ID): Promise<ClientModel | undefined> => {
  return axios
    .get(`${CLIENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<ClientModel>>) => response.data)
    .then((response: Response<ClientModel>) => response.data)
}

const createClient = (user: ClientModel): Promise<ClientModel | undefined> => {
  return axios
    .put(CLIENT_URL, user)
    .then((response: AxiosResponse<Response<ClientModel>>) => response.data)
    .then((response: Response<ClientModel>) => response.data)
}

const updateClient = (user: ClientModel): Promise<ClientModel | undefined> => {
  return axios
    .post(`${CLIENT_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<ClientModel>>) => response.data)
    .then((response: Response<ClientModel>) => response.data)
}

const deleteClient = (userId: ID): Promise<void> => {
  return axios.delete(`${CLIENT_URL}/${userId}`).then(() => {})
}

const deleteSelectedClients = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${CLIENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

  export {getClients, deleteClient, deleteSelectedClients, getClientById, createClient, updateClient}