// @ts-nocheck
import {Column} from 'react-table'
import {ClientInfoCell} from './ClientInfoCell'
import {ClientLastLoginCell} from './ClientLastLoginCell'
import {ClientTwoStepsCell} from './ClientTwoStepsCell'
import {ClientActionsCell} from './ClientActionsCell'
import {ClientSelectionCell} from './ClientSelectionCell'
import {ClientCustomHeader} from './ClientCustomHeader'
import {ClientSelectionHeader} from './ClientSelectionHeader'
import {ClientModel} from '../../core/_models'

const clientsColumns: ReadonlyArray<Column<ClientModel>> = [
  {
    Header: (props) => <ClientSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ClientSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ClientCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    // id: 'id',
    accessor: 'name',
    //Cell: ({...props}) => <ClientInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ClientCustomHeader tableProps={props} title='Mobile Number' className='min-w-125px' />,
    accessor: 'mobileNumber',
  },
  {
    Header: (props) => <ClientCustomHeader tableProps={props} title='City' className='min-w-125px' />,
    accessor: 'city',
  },
  {
    Header: (props) => <ClientCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
  },
  // {
  //   Header: (props) => (
  //     <ClientCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <ClientLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <ClientCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <ClientTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <ClientCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ClientActionsCell id={props.data[props.row.index].id} />,
  },
]

export {clientsColumns}
