import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ClientsListWrapper} from './client-list/ClientList'

const clientsBreadcrumbs: Array<PageLink> = [
  // {
  //   title: 'Client Management',
  //   path: '/apps/user-management/users',
  //   isSeparator: false,
  //   isActive: false,
  // },
  // {
  //   title: '',
  //   path: '',
  //   isSeparator: true,
  //   isActive: false,
  // },
]

const ClientsPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={clientsBreadcrumbs}>Client list</PageTitle>
      <ClientsListWrapper />
    </>
  )
}

export default ClientsPage
